import { useGetAvatar } from "~/hooks/useGetAvatar";
import { Option } from "..";
import { getInitials } from "~/utils/getInitials";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../Tooltip";
import Image from "next/image";
import { AvatarType } from "~/queries/fetchAvatar";
import { Avatar, AvatarFallback, AvatarImage } from "../../Avatar";

export const ListView = ({
  v,
  removeJob,
  options,
  dataType,
}: {
  v: any;
  removeJob: (v: any) => void;
  options: Option[];
  dataType: string;
}) => {
  const { data: avatar } = useGetAvatar(v?.id, AvatarType.jobSeeker);

  const label = options.find(elem => elem.value === v)?.label;
  const value = options.find(elem => elem.value === v)?.value;

  return (
    <div
      className="flex cursor-pointer items-center justify-between px-1"
      onClick={() => removeJob(v)}
    >
      <div className="flex gap-2">
        {dataType === "candidate" && (
          <>
            <Avatar className="h-5 w-5">
              <AvatarImage src={avatar ?? ""} alt={v?.name} />
              <AvatarFallback className="text-body-1 font-normal">
                {getInitials(label)}
              </AvatarFallback>
            </Avatar>
            <span className="text-sm font-normal">{label}</span>
          </>
        )}

        {dataType === "job" && (
          <span className="text-sm font-normal">{`${value} - ${label}`}</span>
        )}
      </div>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Image src="/images/xmark.svg" alt="" width={16} height={16} />
          </TooltipTrigger>
          <TooltipContent>
            <p className="z-50">Remove</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};
