"use client";

import { useQuery } from "@tanstack/react-query";
import { AvatarType, fetchAvatar } from "~/queries/fetchAvatar";
import { ReactQueryHook } from "./hooks.types";

type UseGetAvatarParams = [number | undefined | null, AvatarType | undefined];
type UseGetAvatarQueryFn = typeof fetchAvatar;

export const useGetAvatar: ReactQueryHook<
  UseGetAvatarParams,
  UseGetAvatarQueryFn
> = (id, type) =>
  useQuery({
    queryKey: useGetAvatar.generateKey(id, type),
    queryFn: params => fetchAvatar(params, { id, type }),
    enabled: !!id && !!type,
  });

useGetAvatar.generateKey = (id, type) => ["fetch-avatar", id, type];
