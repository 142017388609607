import { tv } from "tailwind-variants";
import { FallBack } from "../Avatar/avatar.stories";

export const profilePicture = tv({
  slots: {
    container: "",
    image: "",
    spinner: "",
    fallback: "text-white",
    falbackIcon: "text-icons-low-contrast",
  },
  variants: {
    size: {
      tiny: {
        container: "w-5 h-5",
        image: "w-5 h-5",
        spinner: "h-2 w-2",
        fallback: "w-5 text-xs",
        falbackIcon: "w-5 h-5",
      },
      xsmall: {
        container: "w-6 h-6",
        image: "w-6 h-6",
        spinner: "h-4 w-4",
        fallback: "w-6 text-xs",
        falbackIcon: "w-6 h-6",
      },
      small: {
        container: "w-8 h-8",
        image: "w-8 h-8",
        spinner: "h-6 w-6",
        fallback: "w-8 text-sm",
        falbackIcon: "w-8 h-8",
      },
      medium: {
        container: "w-10 h-10",
        image: "w-10 h-10",
        spinner: "h-8 w-8",
        fallback: "w-10 text-base",
        falbackIcon: "w-10 h-10",
      },
      large: {
        container: "w-16 h-16",
        image: "w-16 h-16",
        spinner: "h-12 w-12",
        fallback: "w-16 text-base",
        falbackIcon: "w-16 h-16",
      },
      custom: {
        image: "w-full h-full",
        spinner: "h-12 w-12",
        fallback: "text-base",
      },
    },
    group: {
      true: {
        container:
          "outline outline-white outline-1 group-hover:brightness-70 group-active:brightness-60",
      },
    },
  },

  defaultVariants: {
    size: "medium",
    group: false,
  },
});
