import { QueryFunctionContext } from "@tanstack/react-query";
import axios from "~/services/axios.service";

export interface PlaceDetailsReturn {
  place_id: string;
  formatted_address: string;
  address_components: {
    long_name: string;
    short_name: string;
    types: string[];
  }[];
  geometry: { location: { lat: number; lng: number } };
}

export const fetchPlaceDetails = (
  { signal }: QueryFunctionContext,
  id: string,
): Promise<PlaceDetailsReturn> =>
  axios
    .get<{ data: { result: PlaceDetailsReturn } }>(`/api/places/${id}`, {
      signal,
    })
    .then(({ data }) => data.data.result);
