"use client";

import React, { useState } from "react";
import * as AvatarPrimitive from "@radix-ui/react-avatar";
import {
  Avatar,
  AvatarContainer,
  AvatarFallback,
  AvatarIcon,
  AvatarImage,
} from "../Avatar";
import { getInitials } from "~/utils/getInitials";
import { hashIntoHexColor } from "~/utils/hashIntoHexColor";
import Spinner from "~/components/spinner";

import { UserIcon } from "@heroicons/react/16/solid";
import { profilePicture } from "./profile-picture.styles";
import { ProfilePictureProps } from "./profile-picture.types";

export const ProfilePicture = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root> &
    ProfilePictureProps
>(
  (
    {
      src,
      fullName = "",
      imageClassName,
      fallbackClassName,
      action,
      icon,
      size = "medium",
      group = false,
      className,
      FallbackImage,
      fallbakcClassName,
      onLoadError,
      onLoadSuccess,
      ...props
    },
    ref,
  ) => {
    const [loaded, setLoaded] = useState(props.loaded || !src);

    const handleStatusChange: AvatarPrimitive.AvatarImageProps["onLoadingStatusChange"] =
      status => {
        const statusEvents: Record<typeof status, VoidFunction> = {
          error: () => {
            onLoadError?.();
            setLoaded(true);
          },
          loaded: () => {
            onLoadSuccess?.();
          },
          loading: () => {
            setLoaded(false);
          },
          idle() {},
        };

        statusEvents[status]();
      };

    const { container, fallback, image, spinner, falbackIcon } = profilePicture(
      {
        size,
        group,
      },
    );

    return (
      <AvatarContainer>
        <Avatar
          {...props}
          ref={ref}
          className={container({ class: className })}
        >
          {icon && loaded && <AvatarIcon>{icon}</AvatarIcon>}

          {src && (
            <AvatarImage
              src={src}
              alt={fullName}
              className={image({ class: imageClassName })}
              onLoad={() => setLoaded(true)}
              onLoadingStatusChange={handleStatusChange}
              data-testid="avatar-image"
            />
          )}

          {!loaded ? (
            <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center">
              <Spinner className={spinner()} />
            </div>
          ) : (
            <>
              {!icon && (
                <AvatarFallback
                  className={fallback({
                    class: [imageClassName, fallbackClassName],
                  })}
                  style={{
                    backgroundColor: fullName && hashIntoHexColor(fullName),
                  }}
                >
                  {fullName
                    ? getInitials(fullName)
                    : FallbackImage ?? (
                        <UserIcon
                          className={falbackIcon({
                            class: fallbackClassName,
                          })}
                        />
                      )}
                </AvatarFallback>
              )}
            </>
          )}
        </Avatar>
        {!!action && action}
      </AvatarContainer>
    );
  },
);
