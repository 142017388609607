import { QueryFunctionContext } from "@tanstack/react-query";
import axios from "~/services/axios.service";

export interface PlacesReturn {
  description: string;
  place_id: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
  };
}

export const fetchPlacesAutoComplete = (
  { signal }: QueryFunctionContext,
  input: string,
  types: string[],
): Promise<PlacesReturn[]> =>
  axios
    .get("/api/places/autocomplete", {
      signal,
      params: { input, types: types.join("|") },
    })
    .then(({ data }) => data.data.predictions);
