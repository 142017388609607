import { InputHTMLAttributes, ReactNode, forwardRef } from "react";
import LocationAutoComplete from "~/components/v2/LocationAutoComplete";
import { PlacesReturn } from "~/queries/fetchPlacesAutoComplete";
import { cn } from "~/utils/cn";

interface LocationFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  error?: string;
  startAdornment?: ReactNode;
  types?: string[];
  setValue: (value: PlacesReturn) => void;
  name: string;
  testId?: string;
  containerClassName?: string;
}

const LocationField = forwardRef<HTMLButtonElement, LocationFieldProps>(
  (
    {
      label,
      error,
      startAdornment,
      required,
      types = ["(cities)"],
      setValue,
      name,
      disabled,
      value,
      placeholder,
      className,
      testId,
      containerClassName,
    },
    ref,
  ) => {
    return (
      <div className={cn("flex w-full flex-col gap-1", containerClassName)}>
        {label && (
          <span className="text-sm font-semibold">
            {label}
            {required && "*"}
          </span>
        )}
        <div className="relative w-full">
          <LocationAutoComplete
            placeholder={placeholder || ""}
            setValue={setValue}
            value={`${value || ""}`}
            testId={testId}
            name={name}
            disabled={disabled}
            className={cn(
              "h-[2.625rem] py-2",
              {
                "border-white-70": !!value,
                "border-[#ccc] text-white-85": !value,
                "border-error bg-[#FFF5F5] text-error placeholder-shown:border-error":
                  error,
                "pl-11": !!startAdornment,
              },
              className,
            )}
            types={types}
            ref={ref}
          />
          <span
            className={cn(
              "absolute left-0 top-0 flex h-full items-center px-3",
              {
                "text-white-85": !value,
                "text-error": error,
              },
            )}
          >
            {startAdornment}
          </span>
        </div>
        {error && (
          <span className="flex items-center gap-1 text-xs text-error">
            <i className="mi-circle-error"></i>
            {error}
          </span>
        )}
      </div>
    );
  },
);

LocationField.displayName = "LocationField";

export default LocationField;
