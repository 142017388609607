import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import {
  PlaceDetailsReturn,
  fetchPlaceDetails,
} from "~/queries/fetchPlaceDetails";

export const useGetPlaceDetails = (
  id: string,
  options: Partial<UseQueryOptions<PlaceDetailsReturn>> = {},
) =>
  useQuery<PlaceDetailsReturn>({
    queryKey: ["places-details", id],
    queryFn: (params): Promise<PlaceDetailsReturn> =>
      fetchPlaceDetails(params, id),
    enabled: !!id,
    ...options,
  });
