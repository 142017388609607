import * as React from "react";
import { cn } from "~/utils/cn";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  name?: string;
  onValueChange?: (name: string, value: string) => void;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, name, onValueChange, ...props }, ref) => {
    return (
      <input
        name={name}
        type={type}
        className={cn(
          "border-input bg-background placeholder:text-muted-foreground focus-visible:ring-ring flex h-9 w-full rounded-md border px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-1 disabled:cursor-not-allowed disabled:opacity-50",
          className,
        )}
        ref={ref}
        onChange={e => {
          onValueChange?.(name!, e.target.value);
        }}
        {...props}
      />
    );
  },
);
Input.displayName = "Input";

export { Input };
