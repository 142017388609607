import { forwardRef, useEffect, useState } from "react";
import useDebounce from "~/hooks/useDebounce";
import { useGetPlacesAutoComplete } from "~/hooks/useGetPlacesAutoComplete";
import { Popover, PopoverContent, PopoverTrigger } from "../Popover";
import {
  Command,
  CommandItem,
  CommandGroup,
  CommandList,
  CommandInput,
} from "../Command";
import { Button } from "../Button";
import { cn } from "~/utils/cn";
import { CommandLoading } from "cmdk";
import Spinner from "~/components/spinner";
import { PlacesReturn } from "~/queries/fetchPlacesAutoComplete";

interface Props {
  value: string;
  setValue: (value: PlacesReturn) => void;
  placeholder: string;
  className?: string;
  disabled?: boolean;
  name: string;
  types: string[];
  testId?: string;
  onBlur?: () => void;
}

const LocationAutoComplete = forwardRef<HTMLButtonElement, Props>(
  (
    {
      value,
      setValue,
      placeholder,
      className,
      disabled,
      types,
      testId,
      onBlur,
    },
    ref,
  ) => {
    const [open, setOpen] = useState(false);
    const [valueSelected, setValueSelected] = useState(value || "");
    const [inputValue, setInputValue] = useState<string>(value || "");
    const debouncedValue = useDebounce<string>(inputValue, 300);

    useEffect(() => {
      setInputValue(value);
    }, [value]);

    const { data, isFetching, isLoading } = useGetPlacesAutoComplete(
      debouncedValue,
      types,
    );
    const handleOpenChange = (state: boolean) => {
      if (!state && onBlur) {
        onBlur();
      }
      setOpen(state);
    };

    const handleSelect = (value: PlacesReturn) => () => {
      setValue(value);
      handleOpenChange(false);
    };

    return (
      <Popover open={open} onOpenChange={handleOpenChange}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            data-testid={testId}
            className={cn(
              "flex w-full flex-row-reverse justify-between font-medium normal-case text-black hover:bg-white active:bg-white",
              !value && "text-muted-foreground",
              className,
            )}
            ref={ref}
            disabled={disabled}
            aria-label={placeholder}
          >
            <i className="mi-chevron-down h-4 w-4" />
            <span className="line-clamp-1">{value || placeholder}</span>
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className="text-popover-foreground data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 z-50 w-popover-trigger rounded-md border bg-white p-0 shadow-md outline-none"
          sideOffset={0}
        >
          <Command
            value={valueSelected}
            onValueChange={setValueSelected}
            shouldFilter={false}
            loop
          >
            <CommandInput
              placeholder="Search location"
              aria-label="Search location"
              onValueChange={setInputValue}
              value={inputValue}
              test-id="location-input"
            />
            <CommandList>
              {isFetching && isLoading && (
                <CommandLoading>
                  <div className="flex h-12 items-center gap-2 p-2">
                    <div className="relative h-5 w-5">
                      <Spinner />
                    </div>{" "}
                    Loading...
                  </div>
                </CommandLoading>
              )}
              {!isFetching && !data?.length && (
                <CommandLoading>
                  <span className="flex h-14 items-center justify-center">
                    {debouncedValue
                      ? "Nothing found."
                      : "Search for location..."}
                  </span>
                </CommandLoading>
              )}
              <CommandGroup className="overflow-none">
                {data?.map((option: PlacesReturn) => (
                  <CommandItem
                    key={option.description}
                    className="line-clamp-1 data-[selected=true]:bg-[#F2F5FA]"
                    onSelect={handleSelect(option)}
                    style={{ display: "-webkit-box" }}
                    title={option.description}
                  >
                    {option.description}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    );
  },
);

LocationAutoComplete.displayName = "LocationAutoComplete";

export default LocationAutoComplete;
