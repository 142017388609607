import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import {
  PlacesReturn,
  fetchPlacesAutoComplete,
} from "~/queries/fetchPlacesAutoComplete";

export const useGetPlacesAutoComplete = (
  input: string,
  types: string[],
  options: Partial<UseQueryOptions> = {}
) =>
  useQuery<any>({
    queryKey: ["places-autocomplete", input],
    queryFn: (params): Promise<PlacesReturn[]> =>
      fetchPlacesAutoComplete(params, input, types),
    enabled: !!input,
    ...options,
  });
